define("discourse/plugins/discourse-zoom/discourse/templates/components/webinar-option-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="webinar-option">
    <a href class="webinar-topic" {{action "selectWebinar"}}>
      {{this.model.topic}}
    </a>
  
    <div class="webinar-schedule">
      {{this.schedule}}
    </div>
  
    <div class="webinar-id">
      ID:
      {{this.model.id}}
    </div>
  </div>
  */
  {
    "id": "/JGkyFCd",
    "block": "[[[10,0],[14,0,\"webinar-option\"],[12],[1,\"\\n  \"],[11,3],[24,6,\"\"],[24,0,\"webinar-topic\"],[4,[38,2],[[30,0],\"selectWebinar\"],null],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"topic\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"webinar-schedule\"],[12],[1,\"\\n    \"],[1,[30,0,[\"schedule\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"webinar-id\"],[12],[1,\"\\n    ID:\\n    \"],[1,[30,0,[\"model\",\"id\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/templates/components/webinar-option-row.hbs",
    "isStrictMode": false
  });
});