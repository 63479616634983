define("discourse/plugins/discourse-zoom/discourse/templates/components/post-top-webinar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.topic.webinar}}
    <div class="webinar-banner">
      <Webinar
        @topic={{this.model.topic}}
        @webinarId={{this.model.topic.webinar.id}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "tGfZt6hM",
    "block": "[[[41,[30,0,[\"model\",\"topic\",\"webinar\"]],[[[1,\"  \"],[10,0],[14,0,\"webinar-banner\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@topic\",\"@webinarId\"],[[30,0,[\"model\",\"topic\"]],[30,0,[\"model\",\"topic\",\"webinar\",\"id\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"webinar\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/templates/components/post-top-webinar.hbs",
    "isStrictMode": false
  });
});